<template>
  <div>
    <h3>Tablero del Estudiante</h3>
    <b-badge class="period" v-if="getPeriod"
      >{{ $getVisibleNames("mesh.period", false, "Período") }} actual:
      {{ getPeriod }}</b-badge
    >
    <div class="dashboard-container">
      <multipane class="custom-resizer" layout="vertical">
        <div class="pane first">
          <div class="card">
            <MattersStudying :user="user"></MattersStudying>
          </div>
          <div class="card">
            <UpcomingEvaluations :user="user"></UpcomingEvaluations>
          </div>
        </div>
        <multipane-resizer></multipane-resizer>
        <div class="pane" :style="{ flexGrow: 1 }">
          <div class="card">
            <StudentGrades :student_id="user.id"></StudentGrades>
            <!-- <LatestScoresComponent :user="user"></LatestScoresComponent> -->
          </div>
          <div class="card">
            <!-- <StudentAchievements :user="user"></StudentAchievements> -->
          </div>
        </div>
      </multipane>
    </div>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters, mapState } from "vuex";
import { Multipane, MultipaneResizer } from "vue-multipane";

export default {
  name: "StudentDashboardView",
  components: {
    // LatestScoresComponent: () =>
    //   import("../StudentDashboard/LatestScoresComponent"),
    UpcomingEvaluations: () =>
      import("../StudentDashboard/UpcomingEvaluations"),
    MattersStudying: () => import("../StudentDashboard/MattersStudying"),
    // StudentAchievements: () =>
    //   import("../StudentDashboard/StudentAchievements"),
    Multipane,
    MultipaneResizer,
    StudentGrades: () => import("./StudentGrades.vue"),
  },
  data() {
    return {
      evaluation_score: null,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
    ...mapState({
      user: "user",
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    getPeriod() {
      if (!this.institution.actual_period) return "";
      return this.institution.actual_period.name;
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
.custom-resizer {
  width: 100%;
  height: fit-content;
}
.custom-resizer > .pane {
  text-align: left;
  padding: 6px;
  overflow: hidden;
  background: transparent;
}
.custom-resizer > .pane > .card {
  color: #fff;
  margin: 0.3rem 0;
  padding: 0.6rem;
  border-radius: 5px;
  min-height: 200px;
  background-color: var(--primary-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -2px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
}
.custom-resizer > .pane ~ .pane {
}
.custom-resizer > .multipane-resizer {
  margin: 0;
  left: 0;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 3px;
    min-height: 80px;
    max-height: fit-content;
    position: absolute;
    top: 50% !important;
    left: 50%;
    margin-top: 3em !important;
    margin-left: -1.5px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  &:hover {
    &:before {
      border-color: #999;
    }
  }
}

.first {
  width: 50%;
  min-width: 33%;
  max-width: 50%;
}

h3 {
  margin: 0 16em;
  margin-bottom: 0.3em;
  border-bottom: 1px solid var(--secondary-color);
}
.dashboard-container {
  width: 100%;
}
.period {
  font-style: italic;
  padding: 0.3em 0.9em;
  margin-bottom: 0.5rem;
}
.student-panel {
  display: flex;
  width: 100%;
}

@media (max-width: 1217px) {
  h3 {
    margin: 0 30%;
    margin-bottom: 0.3em;
  }
}

@media (max-width: 810px) {
  .dashboard-container {
    display: block;
    padding: 0.1rem 1rem;
  }
  h3 {
    margin: 0 20%;
    margin-bottom: 0.3em;
  }
  .custom-resizer {
    display: block;
  }
  .custom-resizer > .multipane-resizer {
    display: none;
  }
  .custom-resizer > .pane {
    width: 100% !important;
    margin-top: 1%;
  }
  .first {
    width: max-content;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>